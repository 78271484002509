import React from 'react'
import Carousel from 'react-slick'
import Img from 'gatsby-image'
import './slider.scss'

const Slider = ({
    slider
})  => {

  const data = slider.edges

  // React Slider settings
  const settings = {
    dots: true,
    fade: true,
    centerMode: true,
    infinite: true,
    speed: 2000,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000
  };

  return (
    <section className='slider'>
      <div className='container'>
        <Carousel {...settings}>
          {data.map((item, key) => {
            return (
              <div key={key}>
                { item.node.quoteImage !== null && item.node.quoteImage.fixed !== null &&
                    <Img fixed={item.node.quoteImage.fixed} />
                }
                { item.node.quoteText &&
                  <h3>"{item.node.quoteText}"</h3>
                }
                { item.node.quoteAuthor &&
                  <h5>{item.node.quoteAuthor}</h5>
                }
              </div>
            )
          })}
        </Carousel>
      </div>
    </section>
  )
}

export default Slider
